/*! ONEWEB.PGR.4.0.19.10-1911131024 | Page Runtime resources | (c) Avalant, Ltd. */
var microflow = angular.module("microflow", [
  "ngRoute",
  "ngCookies",
  "pascalprecht.translate",
  "ngAnimate",
  "ui.scroll",
  "purplefox.numeric",
  "permission"
]);
var app = microflow;
app.config([
  "$routeProvider",
  "$locationProvider",
  function($routeProvider, $locationProvider) {
    var urlMapping = "";
    var stateName = "";
    var objRouteParam = { cache: false, templateUrl: "" };
    var objStateParam = { cache: false, url: "", templateUrl: "" };

    for (var i = 0; i < routeConfigList.length; i++) {
      var objRouteConfig = routeConfigList[i];

      objRouteParam = { cache: false, templateUrl: "" };
      objStateParam = { cache: false, url: "", templateUrl: "" };

      urlMapping = objRouteConfig.urlRoute;
      stateName = objRouteConfig.routeName;

      objRouteParam.cache = false;
      objRouteParam.templateUrl = objRouteConfig.templateName;

      objStateParam.cache = false;
      objStateParam.url = urlMapping;
      objStateParam.templateUrl = objRouteConfig.templateName;

      var routeParams = {
        cache: false,
        templateUrl: objRouteConfig.templateName,
        controller: "microflowCtrl",
        memberOnly: objRouteConfig.memberOnly,
        permissions: objRouteConfig.accessRoles,
        myPageName: objRouteConfig.routeName,
        pageTitle: objRouteConfig.pageTitle
      };
      if (objRouteConfig.isHome && objRouteConfig.isHome == "Y") {
        $routeProvider.when("/", routeParams);
      }
      $routeProvider.when(urlMapping, routeParams);
    }
    $locationProvider.html5Mode(true);
  }
]);
app.run([
  "$rootScope",
  "$location",
  "authenFac",
  "$anchorScroll",
  "iam2",
  function($rootScope, $location, authenFac, $anchorScroll, iam2) {
    if (
      window.__pgd__.iam2Connected == 1 ||
      window.__pgd__.iam2_connected == 1
    ) {
      iam2.getIAM2Permissions(null, true);
    }
    $rootScope.$on("$routeChangeStart", function(event, next) {
      if (next.memberOnly === "Y") {
        if (!authenFac.isLogin) {
          if (authenFac.loginURL === "") {
            for (var i = 0; i < routeConfigList.length; i++) {
              var objRoute = routeConfigList[i];
              if (objRoute.isLogin === "Y") {
                myApp.title = objRoute.menuName;
                authenFac.loginURL = objRoute.urlRoute;
                $location.path(objRoute.urlRoute);
                break;
              }
            }
          } else {
            $location.path(authenFac.loginURL);
          }
        } else {
          var objTemp = authenFac.getPermissingOfObjectAccess(
            next.myPageName,
            "PAGE"
          );
          if (objTemp === undefined || objTemp === null) {
            console.error("redirect 403");
          }
        }
      }
    });

    $rootScope.$on("$routeChangeSuccess", function(event, next) {
      $anchorScroll();
      if ($("body").is(".modal-open")) {
        $("body").removeClass("modal-open");
      }
      document.title = next.pageTitle || next.myPageName;
    });
    $rootScope.checkAnoymousLogin = function() {
      if (!localStorage.getItem("header")) {
        authenFac.anonymousLogin().then(
          function(inResult) {
            authenFac.anonymousToken = inResult;
            authenFac.getAnonymousPermissionRole(authenFac.anonymousToken).then(
              function(inObj) {
                var perms = inObj.Permission;
                var permRole = perms.role;
                var permObjectAccesses = perms.objectAccesses;
                var roles = [];
                for (var i = 0; i < permRole.length; i++) {
                  roles.push(permRole[i].role_name);
                }
                authenFac.userRoles = roles;
                for (var i = 0; i < permObjectAccesses.length; i++) {
                  objectAccesses.push(permObjectAccesses[i]);
                }
                authenFac.objectAccesses = objectAccesses;
                authenFac.isLogin = false;
              },
              function(inObj) {
                console.warn("GetAnonymousPermissionRole fail");
              }
            );
          },
          function(inResult) {
            console.warn("Authen anonymousLogin fail");
          }
        );
      }
    };
    $rootScope.checkAnoymousLogin();
  }
]);

app.factory("iam2", [
  "$http",
  "$cookies",
  "$templateCache",
  "$route",
  "PermPermissionStore",
  function($http, $cookies, $templateCache, $route, PermPermissionStore) {
    return {
      setPermissions: function(permissions, isReload) {
        return new Promise(function(resolve, reject) {
          try {
            var permissionLs = permissions || [];
            PermPermissionStore.defineManyPermissions(permissionLs, function() {
              return true;
            });
            if (isReload) {
              var currentPageTemplate = $route.current.templateUrl;
              $templateCache.remove(currentPageTemplate);
              $route.reload();
            }
            resolve(permissionLs);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        });
      },
      getPermissions: function() {
        return PermPermissionStore.getStore();
      },
      setCookieIAM2Token: function(accessToken) {
        $cookies.put("IAM2_ACCESS_TOKEN", accessToken);
      },
      removeCookieIAM2Token: function() {
        $cookies.remove("IAM2_ACCESS_TOKEN");
        this.setPermissions([]);
      },
      clearPermissions: this.removeCookieIAM2Token,
      getIAM2Permissions: function(accessToken, isRouteReload) {
        var _this = this;
        return new Promise(function(resolve, reject) {
          var shouldReloadRoute = false;
          if (isRouteReload) {
            shouldReloadRoute = isRouteReload;
          }
          try {
            var iam2RestUrl = window.IAM2_REST_URL;
            var iam2AccessToken;
            if (accessToken) {
              iam2AccessToken = accessToken;
            } else {
              if ($cookies.get("IAM2_ACCESS_TOKEN")) {
                iam2AccessToken = $cookies.get("IAM2_ACCESS_TOKEN");
              } else {
                console.warn("Not found IAM2 access token!");
                return;
              }
            }
            var iam2url = iam2RestUrl + "/perms/";
            var req = {
              method: "get",
              url: iam2url + iam2AccessToken,
              headers: {
                "Content-Type": "application/json"
              }
            };
            $http(req).then(
              function(res) {
                var data = res.data;
                if (data && data.Permission && data.Permission.objectAccesses) {
                  _this.setCookieIAM2Token(iam2AccessToken);
                  var objectAccessLs = [];
                  var iamObjectAccess = data.Permission.objectAccesses;
                  iamObjectAccess.forEach(function(item) {
                    var objRef = item.object_ref_id;
                    objectAccessLs.push(objRef);
                  });
                  _this
                    .setPermissions(objectAccessLs, shouldReloadRoute)
                    .then(function(res) {
                      resolve(res);
                    })
                    .then(function(error) {
                      reject(error);
                    });
                } else {
                  reject(res);
                }
              },
              function(error) {
                _this.setPermissions([], shouldReloadRoute);
                reject(error);
              }
            );
          } catch (error) {
            console.error(error);
            reject(error);
          }
        });
      }
    };
  }
]);

app.directive("pgHref", [
  function() {
    function link(scope, element, attrs) {
      var url = attrs.pgHref;
      element.on("click", function(e) {
        if (e && e.preventDefault) e.preventDefault();
        var targetName = "_self";
        if (attrs.target) {
          targetName = attrs.target;
        }
        window.open(url, targetName);
      });
    }
    return { link: link };
  }
]);

app.directive("pgRef", [
  function() {
    function link(scope, element, attrs) {
      var url = attrs.pgRef;
      element.on("click", function(e) {
        if (e && e.preventDefault) e.preventDefault();
        var query = attrs.pgLinkData;
        scope.pageGo(url, query);
      });
    }
    return { link: link };
  }
]);

app.directive("pgMref", [
  function() {
    function link(scope, element, attrs) {
      var goTo = attrs.pgMref;
      var url = "mailto:" + goTo;
      element.attr("href", url);
      element.on("click", function(e) {
        if (e && e.preventDefault) e.preventDefault();
        location.href = url;
      });
    }
    return { link: link };
  }
]);

app.directive("pgTref", [
  function() {
    function link(scope, element, attrs) {
      var goTo = attrs.pgTref;
      var url = "tel:" + goTo;
      element.attr("href", url);
      element.on("click", function(e) {
        if (e && e.preventDefault) e.preventDefault();
        location.href = url;
      });
    }
    return { link: link };
  }
]);

app.directive("pgHiddenOnload", [
  function() {
    function link(scope, element, attrs) {
      if (element) element.addClass("ng-hide");
    }
    return { link: link };
  }
]);

app.directive("pgLabel", [
  function() {
    function link(scope, element, attrs) {
      var label = attrs.pgLabel;
      element.append(label);
    }
    return { link: link };
  }
]);

app.directive("pgSelectModel", [
  function() {
    return {
      restrict: "A",
      scope: {
        pgSelectModel: "=",
        pgSelectList: "=",
        pgSelectMappingTo: "="
      },
      link: function(scope, element, attrs) {
        try {
          var valueKey = attrs.pgSelectModelValueField;
          var pgSelectModelWatch;
          var pgSelectMappingToWatch;
          function setDefaultValue(dataList, value) {
            var defaultValue = dataList.find(function(obj) {
              return obj[valueKey] == value;
            });
            if (defaultValue) {
              scope.pgSelectModel = defaultValue;
            }
          }
          scope.$watch("pgSelectList", function(data) {
            if (data && attrs.pgSelectMappingTo) {
              if (!attrs.pgStateStore) {
                var defaultValue = data.find(function(obj) {
                  return obj[valueKey] == scope.pgSelectMappingTo;
                });
                if (defaultValue) {
                  scope.pgSelectModel = defaultValue;
                }
              } else if (!pgSelectMappingToWatch) {
                pgSelectMappingToWatch = scope.$watch(
                  "pgSelectMappingTo",
                  function(df) {
                    setDefaultValue(data, df);
                  }
                );
                if (scope.pgSelectMappingTo) {
                  setDefaultValue(data, scope.pgSelectMappingTo);
                }
              }
              if (!pgSelectModelWatch) {
                pgSelectModelWatch = scope.$watch("pgSelectModel", function(
                  selected
                ) {
                  if (selected) {
                    scope.pgSelectMappingTo = selected[valueKey];
                  } else {
                    scope.pgSelectMappingTo = selected;
                  }
                });
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
  }
]);

app.directive("pgStateStore", [
  function() {
    function link(scope, element, attrs) {
      try {
        var prop = attrs.pgStateStore;
        var propList = prop.split("|");
        if (propList.length == 2) {
          var state = propList[0];
          var store = propList[1];
          var checkDefault = 1;
          var unwatch = scope.$watch(store, function(data) {
            if (data) {
              checkDefault++;
              eval(`scope.${state} = "${data}"`);
            }
            if (checkDefault == 2) {
              unwatch();
            }
            scope.update();
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    return {
      restrict: "A",
      link: link
    };
  }
]);

app.directive("pgAccessControl", [
  "authenFac",
  function(authenFac) {
    function link(scope, element, attrs) {
      if (element) {
        var objAccessList = authenFac.objectAccesses;
        var objectRefId = element.attr("pgAccessControl");
        var objAccess = objAccessList.find(function(x) {
          return (
            x.object_type.toUpperCase() === "COMPONENT" &&
            x.object_ref_id === objectRefId
          );
        });

        if (objAccess !== undefined && objAccess.access !== undefined) {
          var accessList = objAccess.access;
          for (var i = 0; i < accessList.length; i++) {
            var accessStr = accessList[i].access_type;
            if (accessStr === "hide") {
              if (element) element.addClass("ng-hide");
            } else if (accessStr === "disable") {
              try {
                element.attr("disabled", true);
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
      }
    }
    return { link: link };
  }
]);

app.directive("pgHeader", function() {
  return {
    restrict: "AEC",
    templateUrl: "templates/_header.html"
  };
});

app.directive("pgFooter", function() {
  return {
    restrict: "AEC",
    templateUrl: "templates/_footer.html"
  };
});

app.directive("pgConnectAction", [
  function() {
    return {
      restrict: "A",
      link: function(scope, element, attrs) {
        if (!attrs.pgConnectAction) return;
        try {
          var nodeAction = PAGE_NODE_ACTION[attrs.pgConnectAction];
          nodeAction.forEach(function(action) {
            if (
              action.actionType == "JavaScript" ||
              action.actionType == "MicroFlow"
            ) {
              var fn = scope[action.actionFn];
              if (action.eventType == "load") {
                var event = $.Event(action.eventType, {
                  target: element
                });
                fn(scope, event);
              }
              element.on(action.eventType, function(event) {
                fn(scope, event);
              });
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
  }
]);

app.factory("qrCodeFactory", function() {
  function QRCode() {
    this.qrCodeWithLogo = {};
  }
  QRCode.prototype.render = function(el, attrs, content) {
    if (!content) {
      this.qrCodeWithLogo.content = "https://oneweb.tech";
    }
    var canvas = el[0].querySelector("canvas");
    var color = {
      dark: attrs.colordark || "#000000",
      light: attrs.colorlight || "#ffffff"
    };
    this.qrCodeWithLogo = Object.assign(this.qrCodeWithLogo, {
      canvas: canvas,
      width: attrs.width,
      nodeQrCodeOptions: {
        errorCorrectionLevel: attrs.errcorrectionlevel,
        color: color
      }
    });
    if (content) {
      this.qrCodeWithLogo.content = content.toString();
    }
    if (attrs.logo) {
      this.qrCodeWithLogo.logo = {
        src: attrs.logo,
        borderSize: 0
      };
    }
    window.QrCodeWithLogo.toCanvas(this.qrCodeWithLogo);
    return this.qrCodeWithLogo;
  };
  return {
    QRCode: new QRCode()
  };
});

app.directive("pgQrcode", [
  "qrCodeFactory",
  function(qrCodeFactory) {
    return {
      restrict: "E",
      template: "<div style='display: inline-block'><canvas></canvas></div>",
      link: function(scope, element, attrs) {
        var qrcode = qrCodeFactory.QRCode;
        var content = attrs.content;
        qrcode.render(element, attrs, content);
      }
    };
  }
]);

app.directive("grcodeData", [
  "qrCodeFactory",
  function(qrCodeFactory) {
    return {
      require: "^?pgQrcode",
      restrict: "A",
      scope: {
        grcodeData: "="
      },
      link: function(scope, element, attrs) {
        scope.$watch("grcodeData", function(e) {
          var qrcode = qrCodeFactory.QRCode;
          qrcode.render(element, attrs, e);
        });
      }
    };
  }
]);

// app.directive("pgChart", [
//   function() {
//     return {
//       restrict: "E",
//       scope: {
//         _chartOutput: "=pgStore"
//       },
//       link: function(scope, element, attrs) {
//         scope.$watch("_chartOutput", function(obj) {
//           try {
//             var el = element[0];
//             el.data = obj;
//           } catch (error) {
//             console.error("[chart-store]:", error);
//           }
//         });
//       }
//     };
//   }
// ]);

app.directive("pgData", [
  function() {
    return {
      restrict: "A",
      scope: {
        _outputData: "=pgData"
      },
      link: function(scope, element, attrs) {
        scope.$watch("_outputData", function(obj) {
          try {
            var el = element[0];
            el.data = obj;
          } catch (error) {
            console.error("[pg-data]:", error);
          }
        });
      }
    };
  }
]);

app.directive("pgDefault", [
  function() {
    return {
      restrict: "A",
      link: function(scope, element, attrs) {
        try {
          var storeDefault = attrs.pgDefault;
          var state = attrs.ngModel;
          if (attrs.pgSelectMappingTo) {
            state = attrs.pgSelectMappingTo;
          }
          var checkDefault = 1;
          var unwatch = scope.$watch(storeDefault, function(data) {
            if (data) {
              if (attrs.type && attrs.type === "date") {
                eval(`scope.${state} = new Date("${data}");`);
              } else {
                eval(`scope.${state} = "${data}";`);
              }
              checkDefault++;
            }
            if (checkDefault == 2) {
              unwatch();
            }
            scope.update();
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
  }
]);

function isArray(arg) {
  return Object.prototype.toString.call(arg) === "[object Array]";
}
function isObject(arg) {
  return Object.prototype.toString.call(arg) === "[object Object]";
}
